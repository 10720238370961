.profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: hsla(0, 0%, 80%, 0.7);
    padding: var(--mantine-spacing-sm);
    border-radius: var(--mantine-radius-md);
    backdrop-filter: blur(10px);
}

/* In mobile, profile can be displayed column */
@media (max-width: 768px) {
    .profile {
        flex-direction: column;
    }

    .avatarGroup {
        margin-bottom: var(--mantine-spacing-md);
    }
}

.avatarGroup {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
}

.description {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    flex: 2;
    height: 100%;
    background-color: hsl(0, 0%, 90%);
    padding: var(--mantine-spacing-sm);
    border-radius: var(--mantine-radius-md);
}

.links {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}
