.blogPostCard {
    background-color: transparent;
    padding: 0 var(--mantine-spacing-md);
    cursor: pointer;
}
/* For mobile, image display will be optimized */
@media (max-width: 768px) {
    .image {
        height: 200px;
        object-fit: cover;
    }
}

.image {
    object-fit: cover;
    border-radius: var(--mantine-radius-md);
    overflow: hidden;
}

.name {
    font-weight: bold;
    color: var(--mantine-color-black);
    line-height: 1.2;
    font-size: rem(32px);
    margin-top: var(--mantine-spacing-xs);
}

.description {
    color: var(--mantine-color-black);
    opacity: 0.7;
    font-weight: normal;
}
